import React from "react";
import "./App.css";

const App: React.FunctionComponent = () => {
  const url = "https://testflight.apple.com/join/130k9hCI";

  return (
    <div className="qr-code-container">
      <h1>
        <a href={url}>TestFlight</a>
      </h1>
      <img
        src={
          "https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=" + url
        }
        alt="QR Code"
      />
    </div>
  );
};

export default App;
